import React, { FC } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { Link } from 'gatsby';

import './styles.scss';
import { IPromoItem } from './models';

export const PromoItem: FC<IPromoItem> = ({
  image,
  boldTitle,
  imageAlt,
  descriptionText,
  buttonText,
  buttonUrl: [{ url }],
}) => (
  <Card className="promo-item">
    <Card.Body>
      <Link to={url} className="promo-item__link">
        <UmbracoImage image={image} alt={imageAlt} className="promo-item__image" />
        <div className="promo-item__content">
          <div className="promo-item__title">{boldTitle}</div>
          <Card.Text className="promo-item__description">{descriptionText}</Card.Text>
          <Button variant="link" className="promo-item__button" aria-label={buttonText}>
            {buttonText}
            <i className="left-chevron-icon" />
          </Button>
        </div>
      </Link>
    </Card.Body>
  </Card>
);

export default PromoItem;
