import React from 'react';
import Container from 'react-bootstrap/Container';
import { useStaticQuery, graphql } from 'gatsby';
import { nanoid } from 'nanoid';
import { PromoRowQuery } from './models';

import PromoItem from './PromoItem';
import './styles.scss';

export const PromoRow = () => {
  const {
    homepageBlocks: { promotionRow },
  }: PromoRowQuery = useStaticQuery(graphql`
    {
      homepageBlocks {
        promotionRow {
          properties {
            boldTitle
            buttonText
            imageAlt
            buttonUrl {
              url
            }
            descriptionText
            image {
              fallbackUrl
              fluid {
                base64
                srcSet
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Container fluid className="promo-row-container" data-testid="promo-row">
      {promotionRow.map(({ properties }) => {
        const key = nanoid();

        return <PromoItem key={key} {...properties} />;
      })}
    </Container>
  );
};

export default PromoRow;
