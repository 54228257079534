import { IBrandInfo } from '../models';

const extractor = (pageHref: string, brandInfo: IBrandInfo): object => {
  const url = new URL(pageHref);

  return {
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pageHref,
    },
    inLanguage: brandInfo.lang,
    author: {
      '@type': 'Person',
      name: brandInfo.brandName,
    },
    publisher: {
      '@type': 'Organization',
      name: brandInfo.brandName,
      url: brandInfo.siteUrl,
      sameAs: brandInfo.sameAs,
      logo: {
        '@type': 'ImageObject',
        url: `${url.origin}/logo.png`,
      },
    },
  };
};

export default extractor;
