import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import articleExtractor from './extractors/article';
import pageExtractor from './extractors/page';
import { PageSchemaType, ExtractorsMapType } from './models';

const extractorsMap: ExtractorsMapType = {
  Article: articleExtractor,
  default: pageExtractor,
};

const PageSchema: FC<PageSchemaType> = ({ type, name, path, data }) => {
  const {
    siteSettings: { siteUrl, siteName, lang },
    brandSettings: brandInfo,
  } = useStaticQuery(
    graphql`
      query {
        siteSettings {
          siteUrl
          siteName
          lang
        }
        brandSettings {
          brandName
          brandLogo {
            fallbackUrl
          }
          youtubeUrl
          facebookUrl
        }
      }
    `
  );
  const pageHref = path ? `${siteUrl}${path}` : siteUrl;

  const brandInformation = {
    ...brandInfo,
    lang,
    siteName,
    siteUrl,
    sameAs: [
      ...(brandInfo.youtubeUrl ? [brandInfo.youtubeUrl] : []),
      ...(brandInfo.facebookUrl ? [brandInfo.facebookUrl] : []),
    ],
  };

  const extractor = extractorsMap[type] || extractorsMap.default;
  const jsonLd = {
    name: name || siteName,
    ...{
      '@context': 'http://schema.org',
      '@type': type,
    },
    ...extractor(pageHref, data, brandInformation),
  };
  const structuredData = JSON.stringify(jsonLd);

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />;
};

export default PageSchema;
