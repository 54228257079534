import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { nanoid } from 'nanoid';
import HeroBannerItem from './HeroBannerItem';
import { HeroBannerQuery } from './models';
import './styles.scss';

export const HeroBanner = () => {
  const {
    homepageBlocks: { carousel },
  }: HeroBannerQuery = useStaticQuery(graphql`
    {
      homepageBlocks {
        carousel {
          structure
          properties {
            boldTitle
            buttonText
            descriptionText
            buttonURL {
              url
            }
            packshotImageAlt
            productImage {
              fluid {
                base64
                originalImg
                srcSet
                title
                url
                src
                aspectRatio
              }
              fallbackUrl
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            image {
              url
              fallbackUrl
            }
            packshotPosition
          }
        }
      }
    }
  `);

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  return (
    <section data-testid="hero-banner" className="hero-banner container-wrapper">
      {carousel?.length ? (
        <h1 className="hero-banner__header">{carousel[0].properties.boldTitle}</h1>
      ) : null}
      <Slider {...settings}>
        {carousel.map(({ properties }) => {
          const key = nanoid();

          return <HeroBannerItem key={key} {...properties} />;
        })}
      </Slider>
    </section>
  );
};

export default HeroBanner;
