import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import PageSchema from 'components/common/PageSchema';
import HeroBanner from 'components/HeroBanner';
import PromoRow from 'components/PromoRow/PromoRow';

const HomePage: FC<{ data: HomepageTypes.Page; pageContext: HomepageTypes.PageContext }> = ({
  data: { homepageSettings },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) => {
  const { ...seo } = homepageSettings;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: false,
  };

  return (
    <Layout crumbsSettings={crumbsSettings} seo={seo} headerTransparent className="home-page">
      <PageSchema
        type="WebPage"
        name={seo.seoMetaTitle}
        data={{
          metaTitle: seo.seoMetaTitle,
          metaDescription: seo.seoMetaDescription,
          metaKeywords: seo.seoMetaKeywords,
        }}
      />
      <HeroBanner />
      <PromoRow />
    </Layout>
  );
};

export const query = graphql`
  query {
    homepageSettings {
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
    }
  }
`;

export default HomePage;
